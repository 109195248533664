import Layout from "../../../../src/components/layout";
import Youtube from "../../../../src/components/youtube";
import { IoLogoFacebook, IoLogoTwitter, IoLogoYoutube } from "react-icons/io";
import * as React from 'react';
export default {
  Layout,
  Youtube,
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoYoutube,
  React
};