exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-issues-volume-4-issue-1-badakere-rao-md": () => import("./../../../src/issues/volume-4/issue-1/badakere-rao.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-badakere-rao-md" */),
  "component---src-issues-volume-4-issue-1-child-development-assessment-tools-md": () => import("./../../../src/issues/volume-4/issue-1/child-development-assessment-tools.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-child-development-assessment-tools-md" */),
  "component---src-issues-volume-4-issue-1-editorial-md": () => import("./../../../src/issues/volume-4/issue-1/editorial.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-editorial-md" */),
  "component---src-issues-volume-4-issue-1-index-md": () => import("./../../../src/issues/volume-4/issue-1/index.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-index-md" */),
  "component---src-issues-volume-4-issue-1-masala-md": () => import("./../../../src/issues/volume-4/issue-1/masala.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-masala-md" */),
  "component---src-issues-volume-4-issue-1-patient-with-fever-md": () => import("./../../../src/issues/volume-4/issue-1/patient-with-fever.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-patient-with-fever-md" */),
  "component---src-issues-volume-4-issue-1-president-letter-md": () => import("./../../../src/issues/volume-4/issue-1/president-letter.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-president-letter-md" */),
  "component---src-issues-volume-4-issue-1-rural-sensitization-program-md": () => import("./../../../src/issues/volume-4/issue-1/rural-sensitization-program.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-rural-sensitization-program-md" */),
  "component---src-issues-volume-4-issue-1-tale-of-continued-diagnosis-md": () => import("./../../../src/issues/volume-4/issue-1/tale-of-continued-diagnosis.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-tale-of-continued-diagnosis-md" */),
  "component---src-issues-volume-4-issue-1-travel-fellowship-in-primary-health-care-md": () => import("./../../../src/issues/volume-4/issue-1/travel-fellowship-in-primary-health-care.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-travel-fellowship-in-primary-health-care-md" */),
  "component---src-issues-volume-4-issue-1-urine-tuberculosis-test-md": () => import("./../../../src/issues/volume-4/issue-1/urine-tuberculosis-test.md" /* webpackChunkName: "component---src-issues-volume-4-issue-1-urine-tuberculosis-test-md" */),
  "component---src-issues-volume-4-issue-2-afpi-karnataka-news-md": () => import("./../../../src/issues/volume-4/issue-2/afpi-karnataka-news.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-afpi-karnataka-news-md" */),
  "component---src-issues-volume-4-issue-2-community-health-md": () => import("./../../../src/issues/volume-4/issue-2/community-health.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-community-health-md" */),
  "component---src-issues-volume-4-issue-2-dilemmas-of-a-gp-md": () => import("./../../../src/issues/volume-4/issue-2/dilemmas-of-a-gp.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-dilemmas-of-a-gp-md" */),
  "component---src-issues-volume-4-issue-2-editorial-md": () => import("./../../../src/issues/volume-4/issue-2/editorial.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-editorial-md" */),
  "component---src-issues-volume-4-issue-2-editorial-note-on-opinion-articles-md": () => import("./../../../src/issues/volume-4/issue-2/editorial-note-on-opinion-articles.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-editorial-note-on-opinion-articles-md" */),
  "component---src-issues-volume-4-issue-2-ethical-challenges-of-digital-health-technologies-aadhaar-india-md": () => import("./../../../src/issues/volume-4/issue-2/ethical-challenges-of-digital-health-technologies-aadhaar-india.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-ethical-challenges-of-digital-health-technologies-aadhaar-india-md" */),
  "component---src-issues-volume-4-issue-2-food-for-thought-md": () => import("./../../../src/issues/volume-4/issue-2/food-for-thought.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-food-for-thought-md" */),
  "component---src-issues-volume-4-issue-2-healthcare-delivery-system-in-india-my-reflections-md": () => import("./../../../src/issues/volume-4/issue-2/healthcare-delivery-system-in-india-my-reflections.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-healthcare-delivery-system-in-india-my-reflections-md" */),
  "component---src-issues-volume-4-issue-2-index-md": () => import("./../../../src/issues/volume-4/issue-2/index.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-index-md" */),
  "component---src-issues-volume-4-issue-2-life-long-learning-strategies-md": () => import("./../../../src/issues/volume-4/issue-2/life-long-learning-strategies.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-life-long-learning-strategies-md" */),
  "component---src-issues-volume-4-issue-2-paradigm-shift-in-cancer-management-md": () => import("./../../../src/issues/volume-4/issue-2/paradigm-shift-in-cancer-management.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-paradigm-shift-in-cancer-management-md" */),
  "component---src-issues-volume-4-issue-2-president-letter-md": () => import("./../../../src/issues/volume-4/issue-2/president-letter.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-president-letter-md" */),
  "component---src-issues-volume-4-issue-2-spice-route-india-cafe-md": () => import("./../../../src/issues/volume-4/issue-2/spice-route-india-cafe.md" /* webpackChunkName: "component---src-issues-volume-4-issue-2-spice-route-india-cafe-md" */),
  "component---src-issues-volume-4-issue-3-a-heros-life-md": () => import("./../../../src/issues/volume-4/issue-3/a-heros-life.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-a-heros-life-md" */),
  "component---src-issues-volume-4-issue-3-afpi-karnataka-activities-2020-md": () => import("./../../../src/issues/volume-4/issue-3/afpi-karnataka-activities-2020.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-afpi-karnataka-activities-2020-md" */),
  "component---src-issues-volume-4-issue-3-editorial-md": () => import("./../../../src/issues/volume-4/issue-3/editorial.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-editorial-md" */),
  "component---src-issues-volume-4-issue-3-from-the-web-md": () => import("./../../../src/issues/volume-4/issue-3/from-the-web.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-from-the-web-md" */),
  "component---src-issues-volume-4-issue-3-index-md": () => import("./../../../src/issues/volume-4/issue-3/index.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-index-md" */),
  "component---src-issues-volume-4-issue-3-lessons-from-home-based-care-md": () => import("./../../../src/issues/volume-4/issue-3/lessons-from-home-based-care.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-lessons-from-home-based-care-md" */),
  "component---src-issues-volume-4-issue-3-moments-in-family-medicine-md": () => import("./../../../src/issues/volume-4/issue-3/moments-in-family-medicine.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-moments-in-family-medicine-md" */),
  "component---src-issues-volume-4-issue-3-president-letter-md": () => import("./../../../src/issues/volume-4/issue-3/president-letter.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-president-letter-md" */),
  "component---src-issues-volume-4-issue-3-reflections-on-a-book-md": () => import("./../../../src/issues/volume-4/issue-3/reflections-on-a-book.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-reflections-on-a-book-md" */),
  "component---src-issues-volume-4-issue-3-supervised-smoking-md": () => import("./../../../src/issues/volume-4/issue-3/supervised-smoking.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-supervised-smoking-md" */),
  "component---src-issues-volume-4-issue-3-varied-managements-of-pcod-md": () => import("./../../../src/issues/volume-4/issue-3/varied-managements-of-pcod.md" /* webpackChunkName: "component---src-issues-volume-4-issue-3-varied-managements-of-pcod-md" */),
  "component---src-issues-volume-5-issue-1-afpi-karnataka-activities-2021-md": () => import("./../../../src/issues/volume-5/issue-1/afpi-karnataka-activities-2021.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-afpi-karnataka-activities-2021-md" */),
  "component---src-issues-volume-5-issue-1-an-alternative-system-of-health-care-services-j-p-naik-md": () => import("./../../../src/issues/volume-5/issue-1/an-alternative-system-of-health-care-services-j-p-naik.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-an-alternative-system-of-health-care-services-j-p-naik-md" */),
  "component---src-issues-volume-5-issue-1-back-bencher-md": () => import("./../../../src/issues/volume-5/issue-1/back-bencher.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-back-bencher-md" */),
  "component---src-issues-volume-5-issue-1-choose-primary-health-care-md": () => import("./../../../src/issues/volume-5/issue-1/choose-primary-health-care.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-choose-primary-health-care-md" */),
  "component---src-issues-volume-5-issue-1-covid-diaries-md": () => import("./../../../src/issues/volume-5/issue-1/covid-diaries.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-covid-diaries-md" */),
  "component---src-issues-volume-5-issue-1-dilemmas-of-a-gp-2-md": () => import("./../../../src/issues/volume-5/issue-1/dilemmas-of-a-gp-2.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-dilemmas-of-a-gp-2-md" */),
  "component---src-issues-volume-5-issue-1-editorial-md": () => import("./../../../src/issues/volume-5/issue-1/editorial.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-editorial-md" */),
  "component---src-issues-volume-5-issue-1-essential-oils-with-proconvulsive-effects-are-physicians-and-patients-aware-md": () => import("./../../../src/issues/volume-5/issue-1/essential-oils-with-proconvulsive-effects-are-physicians-and-patients-aware.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-essential-oils-with-proconvulsive-effects-are-physicians-and-patients-aware-md" */),
  "component---src-issues-volume-5-issue-1-family-medicine-transforming-the-dying-art-of-listening-in-clinical-practice-md": () => import("./../../../src/issues/volume-5/issue-1/family-medicine-transforming-the-dying-art-of-listening-in-clinical-practice.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-family-medicine-transforming-the-dying-art-of-listening-in-clinical-practice-md" */),
  "component---src-issues-volume-5-issue-1-from-the-web-md": () => import("./../../../src/issues/volume-5/issue-1/from-the-web.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-from-the-web-md" */),
  "component---src-issues-volume-5-issue-1-index-md": () => import("./../../../src/issues/volume-5/issue-1/index.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-index-md" */),
  "component---src-issues-volume-5-issue-1-president-letter-md": () => import("./../../../src/issues/volume-5/issue-1/president-letter.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-president-letter-md" */),
  "component---src-issues-volume-5-issue-1-vasodilators-in-clinical-day-to-day-practice-md": () => import("./../../../src/issues/volume-5/issue-1/vasodilators-in-clinical-day-to-day-practice.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-vasodilators-in-clinical-day-to-day-practice-md" */),
  "component---src-issues-volume-5-issue-1-vasodilators-in-clinical-day-to-day-practice-table-1-md": () => import("./../../../src/issues/volume-5/issue-1/vasodilators-in-clinical-day-to-day-practice-table1.md" /* webpackChunkName: "component---src-issues-volume-5-issue-1-vasodilators-in-clinical-day-to-day-practice-table-1-md" */),
  "component---src-issues-volume-6-issue-1-ayushman-bharat-an-untapped-valuable-resource-md": () => import("./../../../src/issues/volume-6/issue-1/ayushman-bharat-an-untapped-valuable-resource.md" /* webpackChunkName: "component---src-issues-volume-6-issue-1-ayushman-bharat-an-untapped-valuable-resource-md" */),
  "component---src-issues-volume-6-issue-1-index-md": () => import("./../../../src/issues/volume-6/issue-1/index.md" /* webpackChunkName: "component---src-issues-volume-6-issue-1-index-md" */),
  "component---src-issues-volume-6-issue-1-peak-into-the-life-of-a-survivor-md": () => import("./../../../src/issues/volume-6/issue-1/peak-into-the-life-of-a-survivor.md" /* webpackChunkName: "component---src-issues-volume-6-issue-1-peak-into-the-life-of-a-survivor-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-for-authors-js": () => import("./../../../src/pages/for-authors.js" /* webpackChunkName: "component---src-pages-for-authors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-js": () => import("./../../../src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */)
}

